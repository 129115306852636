<template>
  <div>
    <LoadingState v-if="isLoading" />
    <main-card v-else>
      <span slot="title1">Homeroom Personal Data</span>
      <span slot="title2">{{ header.kelas }}</span>
      <span slot="sub-title1">{{ header.totalMurid }} Students</span>
      <span slot="sub-title2"
        >{{ header.tahunAjar }} ({{ header.semester }})</span
      >
      <div slot="content">
        <template v-if="!menus.length">
          <div>No Students Found</div>
        </template>
        <template v-else>
          <div class="d-flex flex-column flex-md-row align-items-center mb-4">
            <div class="w-100 w-lg-75">
              <a-divider orientation="left">
                <h3>Personal Data</h3>
              </a-divider>
            </div>
            <div class="w-auto d-flex flex-column flex-md-row ml-3">
              <div class="mr-2">
                <a-button
                  :loading="loadingExport"
                  @click.prevent="exportAll"
                  shape="round"
                  icon="file"
                  size="large"
                  >EXPORT ALL</a-button
                >
              </div>
              <template v-if="!isEdit">
                <a-button
                  @click.prevent="toggleEdit"
                  shape="round"
                  type="primary"
                  icon="edit"
                  size="large"
                  >EDIT</a-button
                >
              </template>
              <template v-else>
                <a-button
                  @click.prevent="cancelEdit"
                  :loading="loadingEdit"
                  type="danger"
                  shape="round"
                  icon="close"
                  size="large"
                  class="mb-3 mb-lg-0 mr-0 mr-md-3"
                  >CANCEL</a-button
                >
                <a-button
                  @click.prevent="saveEdit"
                  shape="round"
                  icon="save"
                  type="primary"
                  size="large"
                  >SAVE</a-button
                >
              </template>
            </div>
          </div>
          <a-row :gutter="16">
            <a-col
              :span="8"
              v-sticky="{
                zIndex: 99,
                stickyTop: 10,
                disabled: isDisabledSticky,
              }"
            >
              <div class="border rounded">
                <div class="card-header card-header-flex">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      w-100
                    "
                  >
                    <div>
                      <h2 class="font-size-18 text-dark">List of Students</h2>
                    </div>
                  </div>
                </div>
                <div class="card-body scrolling-card">
                  <template v-if="loadingListPersonalData">
                    <a-skeleton
                      v-for="item in 5"
                      :key="item"
                      active
                      :paragraph="{ rows: 2 }"
                    />
                  </template>
                  <Menu
                    v-else
                    :menus="menus"
                    :menuActive="menuActive"
                    @menu-change="menuChange"
                  />
                </div>
                <div class="card-footer">
                  <kbd class="bg-primary">↑</kbd>
                  <kbd class="bg-primary mx-2">↓</kbd>
                  <span>To Navigate</span>
                </div>
              </div>
            </a-col>
            <a-col :span="16">
              <div class="border rounded p-4">
                <template v-if="loadingDetailPersonalData || !menus.length">
                  <a-skeleton
                    v-for="item in 5"
                    :key="item"
                    active
                    :paragraph="{ rows: 2 }"
                  />
                </template>
                <template v-else>
                  <!-- {{menuName}} -->
                  <div
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      mb-3
                    "
                  >
                    <div class="container-profile">
                      <div
                        class="
                          kit__utils__avatar
                          kit__utils__avatar--size130
                          kit__utils__avatar--rounded
                          border border-primary
                          shadow
                        "
                      >
                        <template v-if="!menuName.murid.id_profil">
                          <img
                            v-if="menuName.murid.jenis_kelamin === 'Laki-Laki'"
                            src="@/assets/Male-Peci.svg"
                            alt="User 11"
                          />
                          <img
                            v-else
                            src="@/assets/Female-Hijab.svg"
                            alt="User 11"
                          />
                        </template>
                        <template v-else>
                          <img
                            :src="`${config.apiUrl}/${menuName.murid.file.filepath}`"
                            alt="User 11"
                          />
                        </template>
                      </div>
                      <a-button
                        v-if="isEdit"
                        @click.prevent="toggleShowUploadProfile"
                        class="button-upload-image"
                        type="default"
                        icon="upload"
                        shape="circle"
                      ></a-button>
                      <upload-profile
                        field="upload"
                        @crop-success="cropSuccess"
                        @crop-upload-success="cropUploadSuccess"
                        @crop-upload-fail="cropUploadFail"
                        v-model="showUploadProfile"
                        :width="300"
                        :height="300"
                        :url="`${config.apiUrl}/api/file/upload_profile_murid`"
                        :params="params"
                        :headers="headers"
                        langType="en"
                        :noSquare="true"
                        img-format="png"
                        :withCredentials="false"
                      ></upload-profile>
                      <!-- <img :src="imgDataUrl"> -->
                    </div>
                    <div class="text-center mt-3">
                      <h5>{{ menuName.murid.nis }}</h5>
                      <h5>{{ menuName.murid.nama }}</h5>
                      <h5>{{ menuName.murid.jenis_kelamin }}</h5>
                    </div>
                  </div>
                  <a-row :gutter="16" class="px-5">
                    <a-col :span="24">
                      <a-form-item label="NISN">
                        <a-input
                          v-model="menuName.murid.nisn"
                          :disabled="true"
                          size="large"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Religion">
                        <a-input
                          v-model="menuName.murid.agama"
                          :disabled="!isEdit"
                          size="large"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Birth Date">
                        <a-input
                          v-model="menuName.murid.ttl"
                          :disabled="!isEdit"
                          size="large"
                          placeholder="Jakarta, 01 Januari 2000"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Phone Number">
                        <a-input
                          v-model="menuName.murid.nomor_telepon"
                          :disabled="!isEdit"
                          size="large"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Email">
                        <a-auto-complete
                          :data-source="dataSource"
                          size="large"
                          class="w-100"
                          :disabled="!isEdit"
                          @change="handleChangeEmail"
                          v-model="menuName.murid.email"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Parent Email">
                        <a-auto-complete
                          :data-source="dataSource"
                          size="large"
                          class="w-100"
                          :disabled="!isEdit"
                          @change="handleChangeEmail"
                          v-model="menuName.murid.email_perwakilan_orangtua"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-item label="Address">
                        <a-textarea
                          :disabled="!isEdit"
                          v-model="menuName.murid.alamat"
                          :auto-size="{ minRows: 3, maxRows: 5 }"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" class="mb-3">
                      <a-form-item label="Ijazah">
                        <a-input
                          v-model="menuName.murid.ijazah"
                          :disabled="true"
                          size="large"
                        />
                      </a-form-item>
                    </a-col>
                    <!-- <template v-if="menuName.murid.orangtuas.length">
                      <a-divider orientation="center">
                        <h3>Parent Section</h3>
                      </a-divider>
                      <a-col :span="24">
                        <a-form-item label="Parent Name">
                          <a-input
                            v-model="menuName.murid.orangtuas[0].nama"
                            :disabled="!isEdit"
                            size="large"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="24">
                        <a-form-item label="Parent Phone Number">
                          <a-input
                            v-model="menuName.murid.orangtuas[0].nomor_telepon"
                            :disabled="!isEdit"
                            size="large"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="24">
                        <a-form-item label="Parent Whatsapp Number">
                          <a-input
                            v-model="menuName.murid.orangtuas[0].nomor_whatsapp"
                            :disabled="!isEdit"
                            size="large"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="24">
                        <a-form-item label="Parent Email">
                          <a-input v-model="menuName.murid.orangtuas[0].email" :disabled="!isEdit" size="large" />
                        </a-form-item>
                      </a-col>
                      <a-col :span="24">
                        <a-form-item label="Parent Email">
                          <a-auto-complete
                            :data-source="dataSource"
                            size="large"
                            class="w-100"
                            :disabled="!isEdit"
                            @change="handleChangeEmail"
                            v-model="menuName.murid.orangtuas[0].email"
                          />
                        </a-form-item>
                      </a-col>
                    </template> -->
                  </a-row>
                </template>
              </div>
            </a-col>
          </a-row>
        </template>
      </div>
    </main-card>
  </div>
</template>

<script>
import config from '@/config'
import VueSticky from 'vue-sticky'
import uploadProfile from 'vue-image-crop-upload'
import { Form8355ExcelHomeroom } from '@/helpers/ledgerExcel.js'
const mainCard = () => import('@/components/app/shared/mainCard')
const LoadingState = () => import('@/components/app/LoadingState')
const Menu = () => import('@/components/app/MenuV2')

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    uploadProfile,
    mainCard,
    LoadingState,
    Menu,
  },
  data() {
    return {
      menuActive: 1,
      isEdit: false,
      loadingEdit: false,
      loadingListPersonalData: false,
      loadingDetailPersonalData: false,
      showUploadProfile: false,
      header: {},
      menus: [],
      dataSource: [],
      params: {
        id_murid: '',
      },
      headers: {
        smail: '*_~',
        token: localStorage.token,
      },
      imgDataUrl: '',
      config,
      loadingExport: false,
    }
  },
  methods: {
    handleChangeEmail(value) {
      this.dataSource =
        !value || value.indexOf('@') >= 0
          ? []
          : [`${value}@gmail.com`, `${value}@gmail.co.id`, `${value}@yahoo.com`, `${value}@yahoo.co.id`]
    },
    toggleShowUploadProfile() {
      this.params.id_murid = this.menuName.murid.id
      this.showUploadProfile = !this.showUploadProfile
    },
    cropSuccess(imgDataUrl, field) {
      // console.log(imgDataUrl)
      // this.imgDataUrl = imgDataUrl
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------')
      // console.log(jsonData)
      this.toggleShowUploadProfile()
      // console.log(jsonData.data.file)
      // const { id, filepath } = jsonData.data.file
      // this.menuName.murid.id_profil = id
      // this.menuName.murid.file.filepath = filepath
      this.fetchPersonalData(this.header.idKelas)
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
    },
    cancelEdit() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to cancel editing ? your changes will be lost</div>
        ),
        onOk: () => {
          this.fetchPersonalData(this.header.idKelas)
          this.toggleEdit()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    saveEdit() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to save editing ? your changes will be saved</div>
        ),
        onOk: () => {
          const data = this.menus
          this.loadingListPersonalData = true
          this.loadingDetailPersonalData = true
          this.toggleEdit()
          for (let idx = 0; idx < data.length; idx++) {
            const emailMurid = data[idx].murid.email
            console.log('emailMurid :>> ', emailMurid)
            const emailOrangTua = data[idx].murid.email_perwakilan_orangtua
            console.log('emailOrangTua :>> ', emailOrangTua)
            const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (emailMurid === '' || emailMurid === null || emailMurid === undefined) {
              data[idx].murid.email = null
            } else {
              const validateEmail = regexEmail.test(emailMurid)
              if (!validateEmail) {
                this.$notification.error({
                  message: 'Error',
                  description: `${data[idx].menu}'s email has not valid. Please use email properly`,
                })
                return this.fetchPersonalData(this.header.idKelas)
              }
            }
            if (emailOrangTua === '' || emailOrangTua === null || emailOrangTua === undefined) {
              data[idx].murid.email_perwakilan_orangtua = null
            } else {
              const validateEmail = regexEmail.test(emailOrangTua)
              if (!validateEmail) {
                this.$notification.error({
                  message: 'Error',
                  description: `${data[idx].menu} parent email has not valid. Please use email properly`,
                })
                return this.fetchPersonalData(this.header.idKelas)
              }
            }
          }
          console.log(data)
          this.$store.dispatch('homeroom/UPDATE_ALL_PERSONAL_DATA', { data })
            .then(isSuccess => {
              this.fetchPersonalData(this.header.idKelas)
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description: 'All Personal Data has been saved',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'All Personal Data has not been saved',
                })
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    toggleEdit() {
      this.isEdit = !this.isEdit
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    fetchHeader(query = '') {
      this.$store.dispatch('homeroom/FETCH_HEADER_PERSONAL_DATA', { idGuru: this.user.id })
        .then(res => {
          this.header = res
          this.fetchPersonalData(res.idKelas, query)
        })
    },
    fetchPersonalData(idKelas, query) {
      this.loadingListPersonalData = true
      this.loadingDetailPersonalData = true
      this.$store.dispatch('homeroom/FETCH_LIST_PERSONAL_DATA', { idKelas })
        .then(res => {
          this.loadingListPersonalData = false
          this.loadingDetailPersonalData = false
          let no = 1
          this.menus = res.map(el => {
            return {
              key: no++,
              menu: `${el.murid.nama}`,
              ...el,
            }
          })
          if (query) {
            this.menuActive = this.menus.filter(el => el.id_murid === query)[0].key
            this.$notification.warning({
              message: 'Warning',
              description: 'This parent does not have an email, please fill email column for sending the report',
            })
          }
        })
    },
    async exportAll() {
      try {
        this.loadingExport = true
        const { idKelas } = this.header
        const data = await this.$store.dispatch('homeroom/FETCH_ALL_HOMEROOM_STUDENT_CLASS_8355', { idKelas })

        const dataExport = data.map((student, i) => {
          const newData = {}
          const { orangtuas } = student
          newData.nama_orang_tua = orangtuas[0]?.nama || '-'
          for (const key in student) {
            if (key === 'jenis_kelamin') {
              const jenisKelamin = student[key]
              if (jenisKelamin === 'Laki-Laki') newData[key] = 'L'
              else if (jenisKelamin === 'Perempuan') newData[key] = 'P'
              else newData[key] = '-'
            } else if (key === 'alamat_lengkap') {
              // SET ALAMAT AS A STRING
              if (!student[key]) newData.alamat = '-'
              else {
                const { rt, rw, kode_pos: kodePos, kota, jalan, kelurahan_desa: kelurahan, kecamatan } = student[key]
                let alamatStr = null
                if (jalan) alamatStr = jalan
                if (rt) {
                  if (Number(rt) < 10) alamatStr += ` RT 00${rt}`
                  else if (Number(rt) >= 10 && Number(rt) < 100) alamatStr += ` RT 0${rt}`
                  else alamatStr += ` RT ${rt}`
                }
                if (rw) {
                  if (Number(rw) < 10) alamatStr += `/00${rw}`
                  else if (Number(rw) >= 10 && Number(rw) < 100) alamatStr += `/0${rw}`
                  else alamatStr += `/${rw}`
                }
                if (kelurahan) alamatStr += ` kel. ${kelurahan}`
                if (kecamatan) alamatStr += ` kec. ${kecamatan}`
                if (kota) alamatStr += ` ${kota}`
                if (kodePos) alamatStr += ` ${kodePos}`
                newData.alamat = alamatStr
              }
              // //
            } else if (key === 'tempat_tanggal_lahir') {
              if (!student[key]) {
                newData.tempat_lahir = '-'
                newData.tanggal_lahir = '-'
              } else {
                newData.tempat_lahir = student[key].tempat || '-'
                newData.tanggal_lahir = student[key].tanggal || '-'
              }
            } else if (key !== 'orangtuas') {
              newData[key] = student[key] || '-'
            }
          }
          return {
            ...newData,
            no: i + 1,
          }
        })
        const { kelas, semester, tahunAjar } = this.header
        const title = `DATA SISWA KELAS ${kelas} SEMESTER ${semester.toUpperCase()} TAHUN AJARAN ${tahunAjar}`
        await Form8355ExcelHomeroom(dataExport, { title, kelas })
        this.$notification.success({
          message: 'Export Success',
          description: 'Export all student into excel.',
        })
        this.loadingExport = false
      } catch (err) {
        this.loadingExport = false
        console.log(err)
        this.$notification.error({
          message: 'Export Failed',
          description: 'Export all student into excel is failed. Please try again later.',
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.murid) {
      this.fetchHeader(this.$route.query.murid)
    } else {
      this.fetchHeader()
    }
    window.addEventListener('keyup', (event) => {
      event.preventDefault()
      if (this.menus.length) {
        if (event.keyCode === 40 && this.menuActive !== this.menus.length) {
          this.menuActive = this.menuActive + 1
        } else if (event.keyCode === 38 && this.menuActive !== 1) {
          this.menuActive = this.menuActive - 1
        }
      }
    })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    menuName() {
      const menu = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.container-profile {
  position: relative;
  .button-upload-image {
    position: absolute;
    bottom: -15px;
    left: 40%;
  }
}
.scrolling-card {
  height: 600px;
  overflow-y: auto;
}
</style>
